import React from "react"
import RelatedArticle from "../../../contentfulModels/Blog/RelatedArticle"

const RelatedPosts = ({ currentId, currentTags, data, language }) => {
  const articlesArray = data.article.slice()

  const removeCurrentId = function(arr, attr, value) {
    let i = arr.length
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1)
      }
    }
    return arr
  }

  removeCurrentId(articlesArray, "id", currentId)

  const relatedArticlesArray = []

  articlesArray.forEach(article => {
    let tagsMatch = 0
    if (article.tags !== null && article.tags !== undefined) {
      article.tags.forEach(relatedTag => {
        if (currentTags !== null && article.tags !== undefined) {
          currentTags.forEach(currentTag => {
            if (relatedTag === currentTag) {
              tagsMatch++
            }
          })
        }
      })
    }
    relatedArticlesArray.push({ article: article, tagsMatch: tagsMatch })
  })

  function SortByTagsMatch(arr) {
    arr.sort((a, b) => (a.tagsMatch < b.tagsMatch ? 1 : -1))
  }

  SortByTagsMatch(relatedArticlesArray)

  const relatedArticlesArrayCut = relatedArticlesArray.slice(0, 3)

  return (
    <>
      {relatedArticlesArrayCut.map(({ article }, index) => (
        <RelatedArticle
          key={index}
          language={language}
          title={article.titleAndLink.name}
          url={article.titleAndLink.url}
          imageSrc={article.image.file.url}
          tagsInEnglish={article.tagsInEnglish}
          tags={article.tags}
          date={article.date}
          summary={article.summary ? article.summary.summary : ""}
        />
      ))}
    </>
  )
}

export default RelatedPosts
