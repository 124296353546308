import React from "react"
import { Link as LinkG } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import RelatedPosts from "./RelatedPosts"

import facebook from "../../../images/blog-articles/socials/facebook.svg"
import twitter from "../../../images/blog-articles/socials/twitter.svg"
import pinterest from "../../../images/blog-articles/socials/printerest.svg"
import linkedin from "../../../images/blog-articles/socials/linkedin.svg"
import mail from "../../../images/blog-articles/socials/mail.svg"
import tags from "../../../images/blog-articles/tags.svg"

import "./Template.scss"

const Template = ({ data, dataLang, language }) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  let articleDate = new Date(data.date)

  let month = monthNames[articleDate.getMonth()]
  let currentDate = articleDate.getDate()
  let year = articleDate.getFullYear()
  let hour = articleDate.getHours()
  let minute =
    articleDate.getMinutes() < 10
      ? `0${articleDate.getMinutes()}`
      : articleDate.getMinutes()

  const document = JSON.parse(data.fullSummary.raw)

  return (
    <div className="template">
      <div className="template__container">
        <div className="template__header">
          <time>{`${month} ${currentDate}, ${year} ${hour}:${minute}`}</time>
          <h1 className="template__title">{data.titleAndLink.name}</h1>
          
          <div className="template__socials">
            {/* <ul className="template__socials_list">
              <li>
                <span>
                  {language === "zh"
                    ? "分享:"
                    : language === "en-US"
                    ? "Share:"
                    : "Share:"}
                </span>
              </li>
              {data.socialLinks[0].name && (
                <li>
                  <a
                    className="template__socials_list_link"
                    href={`https://${data.socialLinks[0].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={facebook} alt={data.socialLinks[0].name} />
                  </a>
                </li>
              )}
              {data.socialLinks[1].name && (
                <li>
                  <a
                    className="template__socials_list_link"
                    href={`https://${data.socialLinks[1].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={linkedin} alt={data.socialLinks[1].name} />
                  </a>
                </li>
              )}
              {data.socialLinks[2].name && (
                <li>
                  <a
                    className="template__socials_list_link"
                    href={`https://${data.socialLinks[2].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt={data.socialLinks[2].name} />
                  </a>
                </li>
              )}
              {data.socialLinks[3].name && (
                <li>
                  <a
                    className="template__socials_list_link"
                    href={`https://${data.socialLinks[3].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={pinterest} alt={data.socialLinks[3].name} />
                  </a>
                </li>
              )}
              {data.socialLinks[4].name && (
                <li>
                  <a
                    className="template__socials_list_link"
                    href={`https://${data.socialLinks[4].url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={mail} alt={data.socialLinks[4].name} />
                  </a>
                </li>
              )}
            </ul> */}
          </div>
        </div>

        <div className="template__content">
          {documentToReactComponents(document)}
        </div>
        <div className="template__tags">
          <span className="template__tags_icon">
            <img src={tags} alt="tags" />
          </span>
          <span>
            <ul className="template__tags_list">
              {data.tags !== null
                ? data.tags.map((tag, index) => (
                    <li key={index}>
                      <LinkG
                        to={`/blog/${data.tagsInEnglish[index]
                          ?.toLowerCase()
                          .replace(" ", "-")}`}
                        className="template__tags_list_link"
                      >
                        {index < data.tags.length - 1 ? `${tag},` : `${tag}`}
                      </LinkG>
                    </li>
                  ))
                : language === "zh"
                ? "沒有標籤"
                : language === "en-US"
                ? "There're no tags"
                : "There're no tags"}
            </ul>
          </span>
        </div>
        <div className="template__link-back">
            <LinkG to="/blog">
              {language === "zh"
                ? "後退:"
                : language === "en-US"
                ? "return to Blog"
                : "return to Blog"}
            </LinkG>
          </div>
        <div className="template__related-posts">
          <h2 className="template__related-posts_title">
            {language === "zh"
              ? "相關文章:"
              : language === "en-US"
              ? "Related posts:"
              : "Related posts:"}
          </h2>
          
          <div className="template__related-posts_table">
            <RelatedPosts
              currentId={data.id}
              currentTags={data.tags}
              data={dataLang}
              language={language}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template
