import React from "react"
import { graphql } from "gatsby"
import BlogLayout from "../../components/Blog/BlogLayout"
import Header from "../../components/Header"
import BlogContentContainer from "../../components/Blog/BlogContentContainer"
import Template from "../../components/Blog/ArticleTemplate/Template"
import Footer from "../../components/Footer"
import { IndexQuery } from "../../components/Query/IndexPage"
import { connect } from "react-redux"

const ArticleTemplate = ({ data, language, dispatch }) => {
  const dataQuery = IndexQuery()

  let dataLang = {}

  dataQuery.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  let dataArticelLang = {}

  data.allContentfulBlogArticle.edges.forEach(({ node }) => {
    if (language === node.node_locale) {
      dataArticelLang = node
    }
  })

  return (
    <BlogLayout>
      <Header
        indexPage={false}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <BlogContentContainer>
        <Template
          data={dataArticelLang}
          dataLang={dataLang.blog}
          language={language}
        />
      </BlogContentContainer>
      <Footer data={dataLang.footer} />
    </BlogLayout>
  )
}

export default connect(state => state.languageReducer, null)(ArticleTemplate)

export const query = graphql`
  query ArticleQuery($url: String) {
    allContentfulBlogArticle(filter: { titleAndLink: { url: { eq: $url } } }) {
      edges {
        node {
          node_locale
          id
          titleAndLink {
            url
            name
          }
          date
          tagsInEnglish
          tags
          fullSummary {
            raw
          }
          #socialLinks {
          #  url
          #  name
          #}
        }
      }
    }
  }
`
