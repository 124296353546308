import React from "react"
import RelatedBlogButton from "../../components/Blog/RelatedBlogButton"
import { Link as LinkG } from "gatsby"

import "./Article.scss"

const RelatedArticle = ({
  title,
  url,
  imageSrc,
  tags,
  date,
  summary,
  tagsInEnglish,
  language,
}) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  let articleDate = new Date(date)

  let month = monthNames[articleDate.getMonth()]
  let currentDate = articleDate.getDate()
  let year = articleDate.getFullYear()
  let hour = articleDate.getHours()
  let minute =
    articleDate.getMinutes() < 10
      ? `0${articleDate.getMinutes()}`
      : articleDate.getMinutes()

  return (
    <article className="article">
      <LinkG className="article__image" to={`/blog/${url}`}>
        <img src={imageSrc} alt="" />
      </LinkG>
      <div className="article__content">
        <div className="article__content_header">
          <div className="article__content_header_tags">
            {tags &&
              tagsInEnglish &&
              tags.map((tag, index) => (
                <LinkG
                  key={index}
                  to={`/blog/${tagsInEnglish[index]
                    .toLowerCase()
                    .replace(" ", "-")}`}
                >
                  {tag}
                </LinkG>
              ))}
          </div>
          <h2>
            <LinkG to={`/blog/${url}`}>{title}</LinkG>
          </h2>
          {date && (
            <time>{`${month} ${currentDate}, ${year} ${hour}:${minute}`}</time>
          )}
        </div>
        <div className="article__content_summary">{summary}</div>
      </div>
      <RelatedBlogButton url={url} language={language} />
    </article>
  )
}

export default RelatedArticle
