import React from "react"
import { Link as LinkG } from "gatsby"

import "./BlogButton.scss"

function RelatedBlogButton({ url, language }) {
  return (
    <div className="blog-button">
      <LinkG to={`/blog/${url}`}>
        <span>
          {language === "en-US"
            ? "Read more"
            : language === "zh"
            ? "閱讀更多"
            : "Read more"}
        </span>
        <svg
          width="24"
          height="14"
          viewBox="0 0 24 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.292861 6.29302C0.480376 6.10552 0.734702 6.00018 0.999889 6.00018H20.5846L16.2911 1.70896C16.1981 1.616 16.1244 1.50564 16.0741 1.38418C16.0237 1.26272 15.9978 1.13255 15.9978 1.00108C15.9978 0.869619 16.0237 0.739442 16.0741 0.617985C16.1244 0.496528 16.1981 0.38617 16.2911 0.293211C16.384 0.200251 16.4944 0.126512 16.6159 0.0762029C16.7373 0.0258938 16.8675 0 16.999 0C17.1305 0 17.2607 0.0258938 17.3821 0.0762029C17.5036 0.126512 17.614 0.200251 17.7069 0.293211L23.7062 6.29213C23.7994 6.385 23.8732 6.49533 23.9236 6.6168C23.9741 6.73827 24 6.86849 24 7C24 7.13151 23.9741 7.26173 23.9236 7.3832C23.8732 7.50467 23.7994 7.615 23.7062 7.70787L17.7069 13.7068C17.614 13.7997 17.5036 13.8735 17.3821 13.9238C17.2607 13.9741 17.1305 14 16.999 14C16.8675 14 16.7373 13.9741 16.6159 13.9238C16.4944 13.8735 16.384 13.7997 16.2911 13.7068C16.1981 13.6138 16.1244 13.5035 16.0741 13.382C16.0237 13.2606 15.9978 13.1304 15.9978 12.9989C15.9978 12.8675 16.0237 12.7373 16.0741 12.6158C16.1244 12.4944 16.1981 12.384 16.2911 12.291L20.5846 7.99982H0.999889C0.734702 7.99982 0.480376 7.89448 0.292861 7.70698C0.105345 7.51948 0 7.26517 0 7C0 6.73483 0.105345 6.48052 0.292861 6.29302Z"
            fill="#16ACD9"
          />
        </svg>
      </LinkG>
    </div>
  )
}

export default RelatedBlogButton
